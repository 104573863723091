<template>
  <div class="col-12 p-1">

    <DataTable paginator showGridlines ref="dt" class="p-datatable-customers" filterDisplay="row"
      responsiveLayout="scroll" dataKey="id" v-model:filters="filters" :loading="loading" :value="notasRecebidasList"
      :rows="15" :rowHover="true">

      <template #header>
        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
          <span class="text-xl text-900 font-bold">Notas Recebidas</span>
        </div>
      </template>

      <template #loading>
        <div class="mt-6"> Carregando Extrato de Crédito... </div>
      </template>

      <Column field="actionsButton" header="Ações">
        <template #body="{ data }">
          <div class="d-flex justify-content-center">
            <Button type="button" icon="pi pi-print" title="Imprimir" @click="imprimirNota(data.link)"
              class="p-button-grid p-button-sm" />
          </div>
        </template>
      </Column>

      <Column field="documento" header="CPF/CNPJ" filterField="documento" sortable>
        <template #body="{ data }">
          {{ data.documento }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="CPF/CNPJ" />
        </template>
      </Column>

      <Column field="nome" header="Prestador" sortable>
        <template #body="{ data }">
          {{ data.nome }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Prestador" />
        </template>
      </Column>

      <Column field="numero" header="N° Nota" sortable>
        <template #body="{ data }">
          {{ data.numero }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Número" />
        </template>
      </Column>

      <Column field="situacao" header="Situação" bodyStyle="text-align: center" sortable>
        <template #body="{ data }">
          {{ data.situacao }}
        </template>
      </Column>

      <Column field="dataFatoGerador" header="Data Fato Gerador" bodyStyle="text-align: center" sortable>
        <template #body="{ data }">
          {{ data.dataFatoGeradorFormatada }}
        </template>
      </Column>

      <Column field="valorServico" header="Valor Serviço" bodyStyle="text-align: right" sortable>
        <template #body="{ data }">
          {{ data.valorSevicoFormatado }}
        </template>
      </Column>

      <Column field="valorCredito" header="Valor Crédito" bodyStyle="text-align: right" sortable>
        <template #body="{ data }">
          {{ data.valorCreditoFormatado }}
        </template>
      </Column>

      <Column field="situacaoCredito" header="Situação Crédito" bodyStyle="text-align: center" sortable>
        <template #body="{ data }">
          {{ data.situacaoCredito }}
        </template>
      </Column>

    </DataTable>

  </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import { ExtratoCreditoService, LoginService } from "@/services";


export default {
  name: "GridExtratoCredito",

  data() {
    return {
      filters: this.initFilters(),
      loading: false,
      notasRecebidasList: [],


    };
  },

  async created(){
    await this.initFilters();
  },

  async mounted() {
    await this.notasRecebidas();
  },

  computed: {
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    urlAutenticacaoNfse() {
      return this.$store.getters.getUrlAutenticacaoNfse;
    },
    currentUser() {
      return LoginService.currentUserValue;
    },
  },

  methods: {

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        documento: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nome: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        numero: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      };
    },

    processando(show) {
      this.loading = show || false;
      this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

    async notasRecebidas() {
      try {
        this.processando(true);

        const docUser = this.currentUser?.usuario?.pessoa.numeroDocumentoJuridico;

        this.notasRecebidasList = await ExtratoCreditoService.findAll(
          this.idCliente,
          this.urlAutenticacaoNfse,
          docUser,
        );

      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar o Extrato de Crédito!");
      } finally {
        this.processando(false);
      }
    },

    async imprimirNota(link) {
      try {
        this.processando(true);

        await ExtratoCreditoService.imprimirNota(link);

      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao imprimir nota!");
      } finally {
        this.processando(false);
      }
    },

  },

};

</script>