<template>

  <div class="card">
    <DataView :value="extratoGeralValores" v-if="extratoGeralValores">
      <template #header>
        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
          <span class="text-xl text-900 font-bold">{{ title }}</span>
        </div>
      </template>
      <template #list="{ data }">
        <div class="col-12">
          <div class="flex xl:flex-row xl:align-items-start p-2 gap-2">
            <div
              class="flex sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-2">
              <div class="flex align-items-center sm:align-items-start pl-4">
                <div class="text-base font-bold text-900">{{ data.label }}</div>
              </div>
              <div class="flex sm:flex-column align-items-center sm:align-items-end pr-4">
                <span class="text-base font-semibold">{{ data.valor }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>

</template>

<script>
import { ExtratoCreditoService, LoginService } from "@/services";
import { MoneyUtil } from "@/utils";

export default {
  name: "Extrato Geral",

  props: {
    title: {
      type:String,
      default: "Extrato Geral",
    },
  },

  data() {
    return {
      loading: false,

      saldoList: [],
    };
  },

  async mounted() {
    await this.saldo();
  },

  computed: {
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    currentUser() {
      return LoginService.currentUserValue;
    },
    extratoGeralValores() {
      const saldo = (this.saldoList ? this.saldoList[0] : null);
      if (saldo === null || saldo == undefined) {
        const valores = [
          { id: "1", label: "Total de Notas", valor: 0 },
          { id: "2", label: "Valor dos Serviços", valor: "R$ 0,00" },
          { id: "3", label: "Créditos Gerados", valor: "R$ 0,00" },
          { id: "4", label: "Créditos Expirados", valor: "R$ 0,00" },
          { id: "5", label: "Créditos Pendentes", valor: "R$ 0,00" },
          { id: "6", label: "Créditos Cancelados", valor: "R$ 0,00" },
          { id: "7", label: "Créditos Disponíveis", valor: "R$ 0,00" },
          { id: "8", label: "Créditos Utilizados", valor: "R$ 0,00" },
        ];

        return valores;
      }

      const valores = [
        { id: "1", label: "Total de Notas", valor: saldo.qtdeNotas },
        { id: "2", label: "Valor dos Serviços", valor: MoneyUtil.formatCurrency(saldo.valorServico) },
        { id: "3", label: "Créditos Gerados", valor: MoneyUtil.formatCurrency(saldo.creditoGerado) },
        { id: "4", label: "Créditos Expirados", valor: MoneyUtil.formatCurrency(saldo.creditoExpirado) },
        { id: "5", label: "Créditos Pendentes", valor: MoneyUtil.formatCurrency(saldo.credditoPendente) },
        { id: "6", label: "Créditos Cancelados", valor: MoneyUtil.formatCurrency(saldo.creditoCancelado) },
        { id: "7", label: "Créditos Disponíveis", valor: MoneyUtil.formatCurrency(saldo.creditoDisponivel) },
        { id: "8", label: "Créditos Utilizados", valor: MoneyUtil.formatCurrency(saldo.creditoUtilizado) },
      ];

      return valores;
    },
  },

  methods: {

    processando(show) {
      this.loading = show || false;
      this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

    async saldo() {
      try {
        this.processando(true);

        const idPessoa = this.currentUser?.usuario?.pessoa.id;

        this.saldoList = await ExtratoCreditoService.findSaldo(
          this.idCliente,
          idPessoa,
        );

      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar o Extrato de Crédito!");
      } finally {
        this.processando(false);
      }
    },

  },

};

</script>