<template>

  <div class="col-12 p-1">

    <DataTable
      paginator
      showGridlines
      ref="dt"
      class="p-datatable-customers"
      filterDisplay="menu"
      responsiveLayout="scroll"
      dataKey="pid"
      v-model:filters="filters"
      :loading="loading" 
      :value="historicoList"
      :rows="15"
      :rowHover="true"  
    >

      <template #header>
        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
          <span class="text-xl text-900 font-bold">Histórico de Utilização dos Créditos</span>
        </div>
      </template>

      <template #loading >
        <div class="mt-6"> Carregando histórico... </div>
      </template>

      <Column field="ano" header="Ano Indicação" sortable>
        <template #body="{ data }">
          {{ data.ano }}
        </template>
      </Column>

      <Column field="inscricaoMunicipal" header="Inscrição Imobiliária" sortable>
        <template #body="{ data }">
          {{ data.inscricaoMunicipal }}
        </template>
      </Column>

      <Column field="endereco" header="Endereço" sortable>
        <template #body="{ data }">
          {{ data.endereco }}
        </template>
      </Column>

      <Column field="valorCreditadoFormatado" header="Valor Creditado IPTU" bodyStyle="text-align: center" sortable>
        <template #body="{ data }">
          {{ data.valorCreditadoFormatado  }}
        </template>
      </Column>

      <Column field="percentualCreditoCalculoFormatado" header="Crédito Utilizado (% IPTU)" bodyStyle="text-align: center" sortable>
        <template #body="{ data }">
          {{ data.percentualCreditoCalculoFormatado.replaceAll("R$", "%")  }}
        </template>
      </Column>

    </DataTable>

  </div>

</template>
<script>
import { ExtratoCreditoService, LoginService } from "@/services";

export default {
  name: "GridExtratoHistorico",

  data() {
    return {
      loading: false,
      historicoList: [],

      filters: {},
    };
  },

  async mounted() {
    await this.historico();
  },

  computed: {
    currentUser() {
      return LoginService.currentUserValue;
    },
  },

  methods: {

    processando(show) {
      this.loading = show || false;
      this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

    async historico() {
      try {
        this.processando(true);

        const idPessoa = this.currentUser?.usuario?.pessoa.id;

        this.historicoList = await ExtratoCreditoService.findHistorico(
          idPessoa
        );

      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar o Histórico!");
      } finally {
        this.processando(false);
      }
    },

  },

};

</script>